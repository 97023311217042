.lato-thin {
  font-family: "Lato", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.lato-light {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.lato-regular {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.lato-bold {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-style: normal;
}
.lato-black {
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.lato-thin-italic {
  font-family: "Lato", sans-serif;
  font-weight: 100;
  font-style: italic;
}
.lato-light-italic {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-style: italic;
}
.lato-regular-italic {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: italic;
}
.lato-bold-italic {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-style: italic;
}
.lato-black-italic {
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-style: italic;
}
.fz-16{
  font-size: 20px;
}
.fz-12{
  font-size: 14px;
}
.fields-display{
  display: grid;
}
.form-control:focus{
  box-shadow: none !important;
  border-color: #ccc !important;
}
.form-control{
  border: 1px solid #ccc;
  border-radius: 999px !important;
  width: 100% !important;
  height: 44px !important;
}
.institution-select-field{
  width: 100% !important;
}
.css-13cymwt-control{
  height: 44px !important;
  border-radius: 999px !important;
}
.css-13cymwt-control:focus{
  height: 44px !important;
  border-radius: 999px !important;
}
.css-t3ipsp-control{
  height: 44px !important;
  border-radius: 999px !important;
  border: 1px solid #ccc !important;
}
.css-t3ipsp-control:hover{
  border: 1px solid #ccc !important;
}
.css-13cymwt-control:hover{
  border: 1px solid #ccc !important;
}
.phone-number{
  .form-control{
    width: 265px !important;
  }
}
/* .educational-institution{
  height: 100px;
} */
.disclaimer{
  font-size: 14px;
}
.mandatory-class{
  color: #FF0000;
}
.category-fields{
  width: 100% !important;
}
.form-check-input:focus {
  box-shadow: none !important;
}
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.consent-form, .form-group {
  width: 100%;
}
.link{
  color: blue;
}
.submit-button{
  font-size: 20px;
}
@media only screen and (max-width: 768px) {
  .form-control {
    width: 100% !important;
  }
  .category-fields{
    width: 100% !important;
  }
}
.error-message{
  color: #FF0000;
}
.input-error{
  border: 1px solid #FF0000 !important;
  border-radius: 999px !important;
}
.thankyou{
  background-color: #3be8b0;
}
.logo{
  height: 150px;
  width: 150px;
}
.fz-24{
  font-size: 24px;
}
.footer-thankyou{
  background-color: #f2f2f2;
}
.header-thankyou{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);;
}
.thankyou-page{
  padding: 5rem !important;
}
.form-check-input{
  border: 2px solid #dee2e6 !important
}
.close-button{
  background: white !important;
  border: 0px !important;
}


.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 720px !important;
  padding: 0 15px;
  margin: 0 auto;
  box-sizing: border-box;
}
.workshop_align{
  margin-left: 5rem;
  margin-top: 2.5rem;
}

@media (max-width: 992px) {
  .main-container {
    max-width: 720px !important;
    padding: 0 10px;
  }
}

@media (max-width: 768px) {
  .main-container {
    max-width: 576px !important;
    padding: 0 5px;
  }
  .workshop_align{
    margin-left: 0;
    margin-top: 1rem;
  }
}

@media (max-width: 576px) {
  .main-container {
    max-width: 100% !important;
    padding: 0 5px;
  }
}